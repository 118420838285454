<ng-container *ngIf="itemDetails">
    <ng-container *ngIf="itemData | async as itemDetail">
        <div class="w-100" id="prTable">
            <p-table #table [lazy]="true" [paginator]="false" [responsive]="true" [rows]="pageSize"
                [rowsPerPageOptions]="[5,10,15,20]" [totalRecords]="itemTotal" [showCurrentPageReport]="true"
                [value]="itemDetail" [selectionPageOnly]="true" [columns]="itemHeader"
                currentPageReportTemplate="Showing {first} to {last} of {{itemTotal}} entries" responsiveLayout>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <ng-container *ngFor="let item of columns">
                            <th *ngIf="item.show"><span *ngIf="item.name !== 'Action'">{{item.name}}</span>
                                <span *ngIf="item.name == 'Action' && !disableBudget">{{item.name}}</span>
                                <span *ngIf="item.name == 'Action' && disableBudget">More Details</span>
                            </th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns" let-rowIndex="rowIndex">
                    <tr>
                        <ng-container *ngFor="let column of columns">
                            <td *ngIf="column.show"><span
                                    *ngIf="column.key !== 'action' && column.key !=='unitRate' && column.key !=='amountWithoutGst' &&
                                column.key !=='totalGstAmount' && column.key !=='total' && column.key !== 'select' && column.key !== 'unitRate' && column.key !== 'itemName' && column.key !== 'budgetCode' ">{{
                                    rowData[column.key]
                                    }}</span>
                                <span *ngIf="column.key =='amountWithoutGst' ||
                                    column.key =='totalGstAmount' || column.key =='total'">{{
                                    rowData[column.key] | currency : 'INR'
                                    }}</span>
                                <span *ngIf="column.key == 'unitRate'">
                                    <span>{{rowData[column.key] | currency : 'INR'}}</span><br />
                                    <span class="document-identifier text-secondary">per
                                        {{rowData['unitOfMeasure']}}</span>
                                </span>
                                <span *ngIf="column.key == 'itemName'">
                                    <span>{{rowData[column.key]}}</span><br />
                                    <span class="document-identifier text-secondary">{{rowData['itemType']}}</span>
                                </span>
                                <span *ngIf="column.key == 'budgetCode'" id="budgetCodeDropdown">
                                    <p-dropdown optionLabel="displayName" optionValue="id" [options]="budgetList"
                                        (onChange)="onBudgetCodeChange($event, rowData,rowIndex)" placeholder="Select"
                                        [formControl]="rowData['budgetDropdownFormControl']"
                                        [disabled]="disableBudget || isDisable">
                                    </p-dropdown>

                                </span>
                                <span *ngIf="column.key == 'select'"
                                    [pTooltip]="!rowData['id'] ? 'Item is unsaved' :''"><input class="form-check-input"
                                        [disabled]="isDisable"
                                        (change)="rowData['id'] ? deleteFieldChange($event.target.checked,rowData['id'],rowData,rowIndex) : nullItemDelete($event.target.checked,rowData['customeDeleteId'],rowData,rowIndex)"
                                        id="externalToCem" role="switch" type="checkbox"></span>
                                <span *ngIf="column.key == 'action'"><i (click)="editItemDetails($event,rowData)"
                                        *ngIf="!disableBudget" class="bi bi-pencil pointer" pTooltip="Edit Item"></i>
                                    <i class="bi pointer"
                                        [ngClass]="disableBudget ? 'bi-card-text' : 'bi-three-dots-vertical'"
                                        (click)="changeGSTDetails(rowData); overlay.toggle($event);"></i>
                                </span>
                            </td>

                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer" *ngIf="showTotalRow">
                    <tr>
                        <td>Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{{totalAmountWoGst | currency : 'INR' }}</td>
                        <td></td>
                        <!-- Display the sum of totalGstAmount -->
                        <!-- <td>{{ totalGstAmount | currency : 'INR' }}</td>
                        <td>{{ total | currency : 'INR' }}</td>
                        <td></td> -->
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </ng-container>
</ng-container>
<p-overlayPanel #overlay>
    <div class="flex flex-column custom-overlay" *ngIf="gstDetails">
        <div class="d-flex gap-3 mb-2">
            <div class="w-50">GST in %</div>
            <div class="w-50">{{gstDetails.gstRate}}{{ gstDetails.gstRate ? '%' : '' }}</div>
        </div>
        <div class="d-flex gap-3 mb-2">
            <div class="w-50">Total GST Amount</div>
            <div class="w-50">{{gstDetails.totalGstAmount | currency: 'INR'}}</div>
        </div>
        <div class="d-flex gap-3 mb-2">
            <div class="w-50">Total Amount (Inc. GST)</div>
            <div class="w-50">{{gstDetails.total | currency: 'INR'}}</div>
        </div>
    </div>
</p-overlayPanel>