<div class="stats-box">
    <div class="stats-header">{{ title }}</div>
    <div class="stats-count" *ngIf="currency">{{ (count ?? 0) | currency: 'INR' }}</div>
    <div class="stats-count" *ngIf="!currency">{{ count ?? 0 }}</div>
    <div class="stats-line">
        <span class="additional-info"
            [ngClass]="{'positive': insightValue >= 0, 'negative': (insightValue < 0 || insightValue == null)}">
            <i class="bi" [ngClass]="insightValue >= 0 ? 'bi-arrow-up' : 'bi-arrow-down'"></i>
            {{ insightValue }}<span *ngIf="isPercentage">%</span>
        </span>
        {{ additionalInfo }}
    </div>
    <div class="stats-icon" [ngClass]="color">
        <i [class]="icon"></i> <!-- or use an <img> tag if using URLs -->
    </div>
</div>