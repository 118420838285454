<div class="d-flex">
    <div class="statistics-section" *ngFor="let statistics of statisticsData">
        <div class="stat-icon-container">
            <div class="stat-icon" [class]="statistics.color">
                <i [class]="statistics.imgClass"></i>
            </div>
            <div class="statistics-heading">{{statistics.heading}}</div>
        </div>
        <div class="statistics-subheading">{{statistics.totalCount}}</div>
        <div class="statistics-amount">{{statistics.totalValue | currency : 'INR'}}</div>
    </div>
</div>