import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, map } from 'rxjs';
import { ServerResponseWithBody } from 'src/app/_models/app.models';
import { GetDocumentData } from 'src/app/_models/pr.models';
import { RestService } from 'src/app/_services/rest.service';
import { getUrlPathFragment } from 'src/app/_static/util';

@Component({
  selector: 'tg-document-queue',
  templateUrl: './document-queue.component.html',
  styleUrls: ['./document-queue.component.scss']
})
export class DocumentQueueComponent implements OnInit, OnChanges {
  @Input() prId!: number;
  @Input() showModalTitle!: boolean;
  id!: number;
  showTitle!: boolean
  supportingDocumentsList!: GetDocumentData[];
  @Input() groupCategoryId!: number;
  @Input() supportingDocuments!: Observable<GetDocumentData[]>;
  categoryId!: number;
  localDocumentList!: GetDocumentData[]
  deleteIds: number[] = [];
  @Output() deleteResponse = new EventEmitter<boolean>();
  @Input() showDeleteAction!: boolean;
  @Input() isDisable: boolean = false;
  constructor(public bsModalRef: BsModalRef, private restService: RestService,) {
  }

  ngOnInit(): void {
    if (this.supportingDocuments) {
      this.getSupportingDoc();
    } else {
      this.localDocumentList = this.supportingDocumentsList;
    }
  }
  getSupportingDoc(): void {
    this.supportingDocuments.subscribe((documents: GetDocumentData[]) => {
      this.localDocumentList = documents;
    });
  }
  ngOnChanges(changes: SimpleChanges): void {

  }
  onClose() {
    this.bsModalRef.hide();
  }
  downloadDocument(url: string, fileName: string) {
    window.open(url, '_blank');
    this.downloadFile(url, fileName);
  }

  private downloadFile(url: string, fileName: string): void {
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    })
      .then(response => response.blob())
      .then(blob => {
        // Create a link element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;

        // Append to the DOM
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
      })
      .catch(error => {
        console.error('Download failed', error);
      });
  }
  deleteDocument(id: number) {
    if (!this.isDisable) {
      //this.id is PR ID
      this.deleteIds = []
      this.deleteIds.push(id)
      const urlFragment = getUrlPathFragment('docs', this.categoryId ? this.categoryId : this.groupCategoryId, this.prId ? this.prId : this.id, 'supporting-documents');
      const body = { ids: this.deleteIds };
      this.restService.deleteWithBody<any>(urlFragment, body).subscribe(
        (response: any) => {
          this.deleteResponse.emit(true)
          // Handle successful delete response
        },
        (error: any) => {
          this.deleteResponse.emit(false)
          // Handle error response
        }
      );
    }
  }
}
