import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EmployeeMasterViewModel, IEmployeeMasterSignupViewModel, ServerResponseWithBody } from '../_models/app.models';
import { getUrlPathFragment, mergeHeaders } from '../_static/util';

@Injectable({
  providedIn: 'root',
})
export class CemRestService {
  constructor(private http: HttpClient) {}

  read<T>(urlFragment: string, options?: { headers?: HttpHeaders}): Observable<T> {
    const headers = mergeHeaders(options?.headers);
    return this.http.get<T>(`${environment.cemUrl}${urlFragment}`, { headers });
  }

  delete<T>(urlFragment: string, options?: { headers?: HttpHeaders}): Observable<T> {
    const headers = mergeHeaders(options?.headers);
    return this.http.delete<T>(`${environment.cemUrl}${urlFragment}`, { headers });
  }

  readBlob(urlFragment: string, options?: { headers?: HttpHeaders}) {
    const headers = mergeHeaders(options?.headers);
    return this.http.get(`${environment.cemUrl}${urlFragment}`, {
      headers: headers,
      responseType: 'blob',
    });
  }

  post<TIn, TOut>(urlFragment: string, data: TIn, options?: { headers?: HttpHeaders}): Observable<TOut> {
    const headers = mergeHeaders(options?.headers);
    return this.http.post<TOut>(`${environment.cemUrl}${urlFragment}`, data, { headers });
  }

  put<TIn, TOut>(urlFragment: string, data: TIn, options?: { headers?: HttpHeaders}): Observable<TOut> {
    const headers = mergeHeaders(options?.headers);
    return this.http.put<TOut>(`${environment.cemUrl}${urlFragment}`, data, { headers });
  }

  // API calls

  getUserDetails(email: string, options?: { headers?: HttpHeaders}): Observable<IEmployeeMasterSignupViewModel> {
    return this.read<ServerResponseWithBody<IEmployeeMasterSignupViewModel>>(getUrlPathFragment('employees', 'by-email', email), options)
    .pipe(map((v) => v.body));
  }
  
  createUser(request: IEmployeeMasterSignupViewModel, options?: { headers?: HttpHeaders}): Observable<ServerResponseWithBody<EmployeeMasterViewModel>> {
    return this.post<IEmployeeMasterSignupViewModel, ServerResponseWithBody<EmployeeMasterViewModel>>(
      getUrlPathFragment('employees', 'regular'), request, options
    )
  }

}
