import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'handleUndefined',
})
export class HandleUndefinedPipe implements PipeTransform {
  transform(
    value: string | number | Date | undefined | null,
    ...args: unknown[]
  ): string | number | Date {
    return value ?? '-- NA --';
  }
}
