import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'tg-show-astrisk',
  templateUrl: './show-astrisk.component.html',
  styleUrls: ['./show-astrisk.component.scss']
})
export class ShowAstriskComponent implements OnInit {
  @Input() control!: AbstractControl;
  constructor() { }

  ngOnInit(): void {
  }

  isRequired(): string {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      return (!!validator && validator['required']) ? '*' : '';
    }
    return '';
  }
}
