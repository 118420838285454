import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { AppVersionService } from './_services/app-version.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'payexpense-frontend';
  isLoginRoute = false;

  constructor(
    private primengConfig: PrimeNGConfig,
    private ar: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private appVersionService: AppVersionService
  ) {
    this.primengConfig.ripple = true;
    this.titleService.setTitle(
      `PayInvoice by TaxGenie v${this.appVersionService.appVersion}`
    );
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const e = event as NavigationEnd;
        if (!!e) {
          this.isLoginRoute = e.url.includes('auth') || e.url.includes('signup');
        }
      });
  }
}
