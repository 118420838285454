<div class="widget-count">
    <div class="draft-count pointer" (click)="navigateToDocument('Draft')"><i class="bi bi-hourglass"></i>
        <span class="content">Draft</span>
        <span class="amount color-sky">{{(draftAmount | amountShortener) || 0}}</span>
        <span class="document-number">Total {{draftCount ? draftCount : 0}} {{documentName}}s</span>
    </div>
    <div class="submitted-count pointer" (click)="navigateToDocument('Submitted')"><i class="bi bi-check2"></i>
        <span class="content">Submitted</span>
        <span class="amount color-blue">{{(submitAmount | amountShortener) || 0}}</span>
        <span class="document-number">Total {{submitCount ? submitCount : 0}} {{documentName}}s</span>
    </div>
    <div class="approved-count pointer" (click)="navigateToDocument('Accepted')"><i class="fa fa-thumbs-up"></i>
        <span class="content">Approved</span>
        <span class="amount color-green">{{(approvedAmount | amountShortener) || 0}}</span>
        <span class="document-number">Total {{approvedCount ? approvedCount : 0}} {{documentName}}s</span>
    </div>
    <div class="rejected-count pointer" (click)="navigateToDocument('Sent Back')"><i class="fa fa-thumbs-down"></i>
        <span class="content">Rejected</span>
        <span class="amount color-red">{{(rejectAmount | amountShortener) || 0}}</span>
        <span class="document-number">Total {{rejectCount ? rejectCount : 0}} {{documentName}}s</span>
    </div>
    <div class="hold-count pointer" (click)="navigateToDocument('Hold')"><i class="bi bi-exclamation-lg"></i>
        <span class="content">Hold</span>
        <span class="amount color-yellow">{{(holdAmount | amountShortener) || 0}}</span>
        <span class="document-number">Total {{holdCount ? holdCount : 0}} {{documentName}}s</span>
    </div>
    <div class="parked-count pointer" (click)="navigateToDocument('Parked')" *ngIf="documentName == 'Invoice'"><i
            class="bi bi-file-check-fill"></i>
        <span class="content">Parked</span>
        <span class="amount color-purple">{{(parkedAmount | amountShortener) || 0}}</span>
        <span class="document-number">Total {{parkedCount ? parkedCount : 0}} {{documentName}}s</span>
    </div>
    <div class="posted-count pointer" (click)="navigateToDocument('Posted')" *ngIf="documentName == 'Invoice'"><i
            class="bi bi-send-fill"></i>
        <span class="content">Posted</span>
        <span class="amount color-olive">{{(postedAmount | amountShortener) || 0}}</span>
        <span class="document-number">Total {{postedCount ? postedCount : 0}} {{documentName}}s</span>
    </div>
    <div class="paid-count pointer" (click)="navigateToDocument('Paid')" *ngIf="documentName == 'Invoice'"><i
            class="fa fa-rupee-sign"></i>
        <span class="content">Paid</span>
        <span class="amount color-grey">{{(paidAmount | amountShortener) || 0}}</span>
        <span class="document-number">Total {{paidCount ? paidCount : 0}} {{documentName}}s</span>
    </div>
</div>