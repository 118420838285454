import { Component, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { map } from 'rxjs';
import { ExpenseStateViewModel, IDocumentApprovalContainerViewModelBase, ServerResponseWithBody, StatusHistoryViewModel } from 'src/app/_models/app.models';
import { RestService } from 'src/app/_services/rest.service';
import { DateConstants, DocumentStateConstants } from 'src/app/_static/document-state-constants';
import { getUrlPathFragment } from 'src/app/_static/util';

@Component({
  selector: 'tg-workflow',
  templateUrl: './workflow.component.html',
  styles: [
  ]
})
export class WorkflowComponent implements OnInit {
  //To Get history
  @Input() documentContainerId!: number;
  // report is for getting creator details
  @Input() report?: IDocumentApprovalContainerViewModelBase;
  
  history: StatusHistoryViewModel[] = [];
  
  constructor(private restService: RestService) { }

  ngOnInit(): void {
    this.getHistoryData(this.documentContainerId);
  }

  getHistoryData(id: number){
    this.restService
    .read<ServerResponseWithBody<ExpenseStateViewModel[]>>(
      getUrlPathFragment('expense-report', 'get', 'states', id)
    )
    .pipe(map((v) => v.body))
    .subscribe((v) => {
      const initialHistoryArray = this.initaliseHistory(v);
      this.history = this.setHistory(initialHistoryArray);
    })
  }

  initaliseHistory(initialDocumentStateArray: ExpenseStateViewModel[]): StatusHistoryViewModel[] {
    let history: StatusHistoryViewModel[] = this.extractActionedStates(initialDocumentStateArray);
    history.unshift(this.generateCreatorEntry())
    history.push(...this.extractUnactionedStates(initialDocumentStateArray));
    return history;
  }

  setHistory(history: StatusHistoryViewModel[]): StatusHistoryViewModel[] {
    let workflowHistory: StatusHistoryViewModel[] = [];
    for (let i = 0; i < history.length; i++) {
      // Add the initial item to the workflow.
      workflowHistory.push(history[i]);
      // Add Creator into the workflow
      if (history[i].status == 'SENT BACK' && i + 1 < history.length) {
        // If there has been a resubmission the created dates of the next times will reflect the new submission date
        let resubmissionDate: string = history[i + 1].createdTimestamp == null ? "null" : history[i + 1].createdTimestamp?.toString().slice(0, 10)!;
        workflowHistory.push(
          {
            level: 0,
            color: '#4f1010',
            deviatedExpense: false,
            date: DateTime.fromFormat(
              resubmissionDate,
              'yyyy-LL-dd',
              {
                zone: 'utc',
              }
            ).toFormat('dd-LL-yyyy'),

            status: 'CREATOR',
            approver: `${this.report?.firstName} ${this.report?.lastName}`,
            approverEmployeeCode: this.report?.employeeCode,
            delegatedApprover: false,
            defaultApprover: false,
            employeeGrade: this.report?.employeeGrade
          }
        )
      };
    }
    workflowHistory = this.cleanUpUnactionedAfterSendback(workflowHistory);
    return workflowHistory;
  }

  extractActionedStates(documentStates: ExpenseStateViewModel[]): StatusHistoryViewModel[] {
    return documentStates
      .filter((m) => m.status !== 'UNACTIONED' && m.status !== 'REJECTED')
      .map((m) => {
        return {
          status: m.status.replace('_', ' '),
          date: m.actionDate,
          color:
            m.status === 'APPROVED'
              ? '#13653f'
              : m.status === 'SENT_BACK'
                ? '#ffc107'
                : m.status === 'REJECTED'
                  ? '#a52834'
                  : '#1365d1',
          level: m.level,
          approver: m.approverFirstName
            ? `${m.approverFirstName} ${m.approverLastName}`
            : m.approver,
          approverEmployeeCode: m.approverEmployeeCode,
          defaultApprover: !!m.defaultTriggerRemarks,
          delegatedApprover: m.delegated,
          deviatedExpense: m.deviationAssignment,
          remarks: m.remarks,
          changeDesk: m.changeDesk,
          changeDeskRemarks: m.changeDeskRemarks,
          createdTimestamp: m.createdTimestamp
        };
      });
  }
  extractUnactionedStates(documentStates: ExpenseStateViewModel[]): StatusHistoryViewModel[] {
    return documentStates
      .filter((m) => m.status === 'UNACTIONED')
      .sort((a, b) => a.level - b.level)
      .map((m) => {
        return {
          status: m.status.replace('_', ' '),
          date: m.actionDate,
          color:
            m.status === 'APPROVED'
              ? '#13653f'
              : m.status === 'SENT_BACK'
                ? '#ffc107'
                : m.status === 'REJECTED'
                  ? '#a52834'
                  : '#1365d1',
          level: m.level,
          approver: m.approverFirstName
            ? `${m.approverFirstName} ${m.approverLastName}`
            : m.approver,
          approverEmployeeCode: m.approverEmployeeCode,
          defaultApprover: !!m.defaultTriggerRemarks,
          delegatedApprover: m.delegated,
          deviatedExpense: m.deviationAssignment,
          remarks: m.remarks,
          changeDesk: m.changeDesk,
          changeDeskRemarks: m.changeDeskRemarks,
          createdTimestamp: m.createdTimestamp
        };
      });
  }

  generateCreatorEntry(): StatusHistoryViewModel {
    return {
      level: 0,
      color: '#4f1010',
      deviatedExpense: false,
      date: DateTime.fromFormat(
        this.report?.submitDate.toString() ?? DateConstants.ZERO_DATE_STRING,
        DocumentStateConstants.DOCUMENT_STATE_DATE_FORMAT,
        {
          zone: DateConstants.ZONE_UTC,
        }
      ).toFormat(DocumentStateConstants.WORKFLOW_HISTORY_DATE_FORMAT),
      status: DocumentStateConstants.CREATOR_STATE_NAME,
      approver: `${this.report?.firstName} ${this.report?.lastName}`,
      approverEmployeeCode: this.report?.employeeCode,
      delegatedApprover: false,
      defaultApprover: false,
      employeeGrade: this.report?.employeeGrade
    }
  }

  cleanUpUnactionedAfterSendback(workflowHistory: StatusHistoryViewModel[]): StatusHistoryViewModel[] {
    if (this.report?.reportStatus == "SENT_BACK") {
      for (let i = workflowHistory.length - 1; i > 0; i--) {
        if (workflowHistory[i].status == 'UNACTIONED') {
          workflowHistory.pop();
        } else {
          // Pop the last added on creator if it exists before leaving
          if (workflowHistory[i].status == 'CREATOR') {
            workflowHistory.pop();
          }
          break;
        }
      }
    }
    return workflowHistory;
  }

}
