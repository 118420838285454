<div #content>
    <p-table #table class="dashboard-budget" [lazy]="true" (onLazyLoad)="getNextPage($event)" [paginator]="true"
        [responsive]="true" [rows]="pageSize" [rowsPerPageOptions]="[5, 10,25,50]" [totalRecords]="totalRecords"
        [showCurrentPageReport]="true" [value]="budegtDetails" [selectionPageOnly]="true" [showPageLinks]="false"
        [showJumpToPageDropdown]="false" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        appendTo="content" [paginatorDropdownAppendTo]="'body'" [responsiveLayout]="'scroll'"
        paginatorPosition="bottom">
        <ng-template pTemplate="header">
            <tr>
                <th>Budget Code

                </th>
                <th>Type

                </th>
                <th>Amount Allocated

                </th>
                <th>Balance Amount

                </th>
                <th>No. Of POs Raised

                </th>
                <th>No. Of Invoices Raised

                </th>
                <th>View Details</th>
            </tr>
        </ng-template>
        <ng-template let-model pTemplate="body">
            <tr #budgetBody>
                <td>{{model.budgetCode}}</td>
                <td>{{model.type}}</td>
                <td>{{model.amount | currency : 'INR':'symbol':'1.0-0'}}</td>
                <td>{{model.balanceAmount | currency : 'INR':'symbol':'1.0-0'}}</td>
                <td>{{model.purchaseOrderCount}}</td>
                <td>{{model.invoiceCount}}</td>
                <td><i (click)="previewBudget(model)" class="bi bi-eye pointer h5" pTooltip="Preview"></i></td>
            </tr>
        </ng-template>
    </p-table>
</div>