<div class="login-left-panel-container p-4">
    <!-- Your common left content goes here -->
    <div class="main-container">
        <div class="logo-container m-3">
            <img src="../../../assets/images/logo/PayInvoice-Logo.png" class="logo" alt="" height="40"><br />
            <span class="tg-app-version-login mt-2 me-3">Version: {{appVersionService.appVersion}}</span>
        </div>
        <div class="tag-lines m-3 mt-5">
            <span><i class="bi bi-check2-square"></i> Create, review & approve PR-PO-Invoice</span><br />
            <span><i class="bi bi-check2-square"></i> Create & utilise corporate budget</span><br />
            <span><i class="bi bi-check2-square"></i> Get approval matrix upto 10 levels</span><br />
            <span><i class="bi bi-check2-square"></i> Create & iterate corporate hierarchy</span><br />
        </div>
        <div class="graphic-image mt-5">
            <img src="../../../assets/images/logo/Graphic-new-Payinvoice.png">
        </div>
    </div>
    <!-- Add more content as needed -->
</div>