import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountShortener'
})
export class AmountShortenerPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      if (value < 1000) {
        return value.toString(); // No formatting needed for numbers less than 1000
      }

      const suffixes = [
        { value: 1e9, symbol: 'B' },     // Billion
        { value: 1e7, symbol: 'Cr' },    // Crore (10 Million, used in the Indian numbering system)
        { value: 1e5, symbol: 'L' },     // Lakh (100 Thousand, used in the Indian numbering system)
        { value: 1e6, symbol: 'M' },     // Million
        { value: 1e3, symbol: 'K' }      // Thousand
      ];

      for (const suffix of suffixes) {
        if (value >= suffix.value) {
          return (value / suffix.value).toFixed(2).replace(/\.00$/, '') + suffix.symbol;
        }
      }
    }
    return value ? value.toString() : '';
  }
}
