<div class="d-flex flex-column base-layout">
  <tg-top-navbar>
    <ng-content select="tg-image-holder"></ng-content>
  </tg-top-navbar>
  <div class="flex-grow-1">
    <tg-sign-up-container>
      <ng-content select="router-outlet"></ng-content>
    </tg-sign-up-container>
  </div>

  <tg-footer *ngIf="false"></tg-footer>
</div>
