export const environment = {
  production: false,
  baseUrl: 'https://demo.payinvoice.online/api/base',
  // use this to connect to peer machines to call API locally and connect to Frontend just change IP
  // baseUrl: 'http://192.168.0.100:8080/api/v1/', 
  // baseUrl: 'http://localhost:8080/api/v1/',
  cemUrl: 'https://demo.payinvoice.online/api/cem',
  authUrl: 'https://demo.payinvoice.online/api/auth',
  // authUrl: 'https://demo.payinvoice.online/api/cam',
  oAuth20Url: 'https://demo.payinvoice.online/api/oauth',
  camUrl: 'https://demo.payinvoice.online/api/cam',
  mdmUrl: "https://demo.payinvoice.online/api/mdm",
  env: "ALL",
  devMode: true,
  url: "https://demo.payinvoice.online/",
  productIdForPasswordTemplate: [3],
  CAM_API_URL: "https://demo.taxgenie.online/cam/api/v1/"
};