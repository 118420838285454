import { PaginationViewModel } from "src/app/_models/app.models"

export interface BudgetDetailsViewModel {
    budgetId: number,
    structureMasterId: number,
    budgetCode: string,
    type: string,
    amount: number,
    balanceAmount: number,
    purchaseOrderCount: number,
    invoiceCount: number
}
export interface GetDashboardConfiguration {
    id: number,
    widgetId: number,
    name: string,
    sequenceId: number,
    companyCode: number,
    isActive: boolean,
    isMandatory: boolean,
    subscriptionChangeDate: any,
    creatingUserId: any
}
export interface DocumentDataRequestBody {
    financialYear: string,
    month: number | null,
    name: string
}
export interface DocumentDataResponseBody {
    name: string,
    lastUpdatedAt: string,
    data: DocumentDetails
}
interface DocumentDetails {
    draftCount: number,
    submittedCount: number,
    approvedCount: number,
    rejectedCount: number,
    draftAmount: number,
    submittedAmount: number,
    approvedAmount: number,
    rejectedAmount: number
}
export const WidgetIdsConstant = {
    PURCHASE_ORDER: 3,
    PURCHASE_REQUEST: 2,
    INVOICE: 1
}
export interface SaveDasboardConfig {
    configs: GetDashboardConfiguration[]
}
export interface BudgetDetailsResponse extends PaginationViewModel {
    data: BudgetDetailsViewModel[];
    name: string;
    lastUpdatedAt: string
}
export interface StatsDataResponseBody {
    name: string,
    lastUpdatedAt: string,
    data: StatsDetails
}
export interface StatsDetails {
    totalVendorCount: number,
    totalVendorInsight: string,
    totalInvoiceCount: number,
    totalInvoiceCountInsight: string,
    totalTaxableAmount: number,
    totalTaxableAmountInsight: string,
    totalInvoiceAmount: number,
    totalInvoiceAmountInsight: string,
    totalTaxAmount: number
    totalTaxAmountInsight: string
}