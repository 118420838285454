import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

const NGX_BOOTSTRAP_MODULES = [
  ModalModule,
  TypeaheadModule,
  BsDatepickerModule,
  TooltipModule
];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...NGX_BOOTSTRAP_MODULES],
  exports: [...NGX_BOOTSTRAP_MODULES],
})
export class NgxBootstrapModule {}
