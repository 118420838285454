import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tg-image-holder',
  templateUrl: './image-holder.component.html',
  styleUrls: ['./image-holder.component.scss'],
})
export class ImageHolderComponent implements OnInit {
  @Input() imageUrl: string = 'assets/images/employee.jpg';
  @Input() height: number = 50;

  constructor() {}

  ngOnInit(): void {}
}
