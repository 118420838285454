import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tg-widget-header',
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.scss']
})
export class WidgetHeaderComponent implements OnInit {
  @Input() widgetTitle!: string;
  @Input() lastUpdatedDate!: string;
  quickSearchValue!: string;
  @Output() quickSearchBlur = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }
  onBlurEmitValue() {
    this.quickSearchBlur.emit(this.quickSearchValue); // Emit the quickSearchValue on blur
  }
}
