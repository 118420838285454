import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BudgetDetailsViewModel } from '../dashboard.model';
import { Router } from '@angular/router';

@Component({
  selector: 'tg-budget-details-widget',
  templateUrl: './budget-details-widget.component.html',
  styleUrls: ['./budget-details-widget.component.scss']
})
export class BudgetDetailsWidgetComponent implements OnInit {
  @Input() budegtDetails!: BudgetDetailsViewModel[]
  @Input() lastUpdatedOn!: string;
  @Output() nextPage = new EventEmitter<any>();
  @Input() totalRecords!: number;
  @Input() pageSize!: number;
  constructor(
    private router: Router,) { }

  ngOnInit(): void {
  }
  previewBudget(data: any) {
    this.router.navigate(['/budget-drilldown'], {
      state: {
        data: data,
        lastUpdatedOn: this.lastUpdatedOn
      }
    });
  }
  getNextPage(event: any) {
    this.nextPage.emit(event)
  }
}
