import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseLayoutComponent } from './base-layout/base-layout.component';
import { TopNavbarComponent } from './top-navbar/top-navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ContainerComponent } from './container/container.component';
import { NavbarModule } from './navbar/navbar.module';
import { LibModule } from '../lib/lib.module';
import { TopBannerComponent } from './top-banner/top-banner.component';
import { BannerSegmentComponent } from './banner-segment/banner-segment.component';
import { PrimengModule } from '../primeng/primeng.module';
import { NgxBootstrapModule } from '../ngx-bootstrap/ngx-bootstrap.module';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DocumentDetailCountWidgetComponent } from './user-dashboard/document-detail-count-widget/document-detail-count-widget.component';
import { BudgetDetailsWidgetComponent } from './user-dashboard/budget-details-widget/budget-details-widget.component';
import { WidgetHeaderComponent } from './user-dashboard/widget-header/widget-header.component';
import { MenuModule } from 'primeng/menu';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from '../shared/shared.module';
import { StatisticsWidgetComponent } from './user-dashboard/statistics-widget/statistics-widget.component';
const COMPONENTS = [
  BaseLayoutComponent,
  TopNavbarComponent,
  FooterComponent,
  ContainerComponent,
  TopBannerComponent,
  BannerSegmentComponent,
];

@NgModule({
  declarations: [...COMPONENTS, UserDashboardComponent, DocumentDetailCountWidgetComponent, BudgetDetailsWidgetComponent, WidgetHeaderComponent, StatisticsWidgetComponent],
  imports: [
    CommonModule,
    NavbarModule,
    LibModule,
    PrimengModule,
    NgxBootstrapModule, DragDropModule, BrowserAnimationsModule, MenuModule, SharedModule
  ],
  exports: [...COMPONENTS, UserDashboardComponent],
  providers: [BsModalService],
})
export class LayoutModule { }
