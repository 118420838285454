import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, map } from 'rxjs';
import { ServerResponseWithBody } from 'src/app/_models/app.models';
import { DocumentIds } from 'src/app/_models/pr.models';
import { RestService } from 'src/app/_services/rest.service';
import { getUrlPathFragment } from 'src/app/_static/util';

@Component({
  selector: 'tg-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {
  id!: number;
  documents = [];
  files: File[] = [];
  totalSize: number = 0;
  maxSize: number = 12 * 1024 * 1024; // 12MB in bytes
  uploadedDocuments: Subject<DocumentIds> = new Subject();
  fileSize: number = 0;
  categoryId!: number;
  constructor(public bsModalRef: BsModalRef, private restService: RestService) { }

  ngOnInit(): void {
  }
  onClose() {
    this.bsModalRef.hide();
  }
  deleteDocument() { }
  getTotalSize(): number {
    return this.files.reduce((total, file) => total + file.size, 0);
  }
  upload(id: number) {
    const totalSize = this.getTotalSize();
    if (this.maxSize < this.totalSize + this.fileSize) {
      alert('Total file size exceeds 12MB limit.');
    } else if (this.files.length > 0) {
      const formData = this.uploadFiles(this.files)
      this.restService.post
        <FormData, ServerResponseWithBody<DocumentIds>>
        (getUrlPathFragment('docs', this.categoryId, this.id, 'supporting-documents'), formData)
        .pipe(map(res => res.body)).subscribe(
          (response: DocumentIds) => {
            // Optionally, clear the file list and reset totalSize
            this.files = [];
            this.totalSize = 0;
            this.uploadedDocuments.next(response)
            this.onClose()
          },
          (error) => {

          }
        )
    } else {
      alert('No files selected for upload.');
    }

  }
  onFileSelected(event: any) {
    const selectedFiles = event.target.files;
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      if (this.totalSize + file.size + this.fileSize <= this.maxSize) {
        this.files.push(file);
        this.totalSize += file.size;
      } else {
        alert('Total file size exceeds 12MB limit.');
        this.files = []
        break;
      }
    }
  }
  removeFile(file: File) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
      this.totalSize -= file.size;
    }
  }
  initiateUpload() {
    document?.getElementById('attachment')?.click();
  }
  uploadFiles(files: File[]) {
    const formData: FormData = new FormData();
    files.forEach(file => formData.append('files', file, file.name));

    return formData
  }
}
