import { Component, Input, OnInit } from '@angular/core';
import { IStatisticsbarModel } from 'src/app/_models/shared.models';

@Component({
  selector: 'tg-statistics-bar',
  templateUrl: './statistics-bar.component.html',
  styleUrls: ['./statistics-bar.component.scss']
})
export class StatisticsBarComponent implements OnInit {
  @Input() statisticsData! : IStatisticsbarModel[];
  constructor() { }

  ngOnInit(): void {
  }

}
