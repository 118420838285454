import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {catchError, map, mergeMap, Observable, of, switchMap, tap} from 'rxjs';
import { AuthService } from '../_services/auth.service';
import {RestService} from "../_services/rest.service";
import {ServerResponseWithBody} from "../_models/app.models";
import {getUrlPathFragment} from "../_static/util";

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private restService: RestService,
    private ar: ActivatedRoute
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.isInRole('admin')
      .pipe(
        tap((v) => {
          if (!v) {
            this.router.navigate(['unauthorized'], {
              queryParams: {
                returnUrl: this.router.url,
              },
            });
          }
        }),
        switchMap(v => {
          return this.restService.read<ServerResponseWithBody<any>>(
            getUrlPathFragment('auxiliary-services', 'check', 'admin', 'eligibility')
          ).pipe(
            map(v => {
              return v.ok
            }),
            catchError(e => {
              this.router.navigate(['network-restriction'], {
                queryParams: {
                  returnUrl: this.router.url,
                },
              });

              return of(false);
            })
          )
        })
      );



    // this.restService.read<ServerResponseWithBody<any>>(
    //   getUrlPathFragment('auxiliary-services', 'check', 'admin', 'eligibility')
    // )
    //   .pipe(
    //     switchMap(v => {
    //       return this.authService.isInRole('admin')
    //         .pipe(
    //           tap((v) => {
    //             if (!v) {
    //               this.router.navigate(['unauthorized'], {
    //                 queryParams: {
    //                   returnUrl: this.router.url,
    //                 },
    //               });
    //             }
    //           })
    //         )
    //     })
    //   );
    // return this.authService.isInRole('admin').pipe(
    //   tap((v) => {
    //     if (!v) {
    //       this.router.navigate(['unauthorized'], {
    //         queryParams: {
    //           returnUrl: this.router.url,
    //         },
    //       });
    //     }
    //   })
    // );
  }
}
