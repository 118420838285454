<div class="mb-3">Workflow</div>
<p-timeline [value]="history" align="left">
    <ng-template let-event pTemplate="content">
        <div class="d-flex flex-column">
            <span class="tg-approver">{{event.status}}</span>
            <span class="tg-approver-byline">{{event.approver}}</span>
            <span class="tg-approver-employee-code" *ngIf="event.approverEmployeeCode">[{{event.approverEmployeeCode}}]</span>
            <span class="tg-default-approver-byline" *ngIf="event.defaultApprover">[Default]</span>
            <span class="tg-delegated-approver-byline" *ngIf="event.delegatedApprover">[Delegated]</span>
            <span class="tg-deviation-approver-byline" *ngIf="event.deviatedExpense">[Deviation]</span>
            <span class="tg-approver-byline" [style]="{color: event.color}">{{event.date}}</span>
            <span class="tg-approver-byline" [style]="{color: event.color}" *ngIf="event.changeDesk">{{event.changeDeskRemarks}}</span>
            <span class="tg-approver-byline">{{event.remarks}}</span>
            <ng-template #nonAdmin>
                <span class="tg-approver-byline" *ngIf="!event.delegatedApprover">{{event.remarks}}</span>
            </ng-template>
        </div>
    </ng-template>

    <ng-template let-event pTemplate="marker">
        <span [style.backgroundColor]="event.color" class="custom-marker shadow-2">
            <span [style]="{fontSize: '0.6rem', fontWeight: 600}">{{event.level}}</span>
        </span>
    </ng-template>
</p-timeline>