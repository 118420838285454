import { Injectable } from '@angular/core';
import packageJson from '../../../package.json';

@Injectable({
  providedIn: 'root',
})
export class AppVersionService {
  private version: string = packageJson.version;

  constructor() {}

  get appVersion() {
    return this.version;
  }
}
