import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const microsoftAuthCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://login.microsoftonline.com/8b396516-4a75-4d82-a217-ab9b2c9383a7/v2.0',
  loginUrl: 'https://login.microsoftonline.com/8b396516-4a75-4d82-a217-ab9b2c9383a7/oauth2/v2.0/authorize',

  // URL of the SPA to redirect the user to after login
  redirectUri: `${environment.url}auth/login`,
  postLogoutRedirectUri: `${environment.url}auth/login`,
  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: 'ce062f41-ce53-432e-aae1-6b6031a39495',

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',
  // dummyClientSecret: 'pexp',

  responseType: 'code',

  tokenEndpoint: 'https://login.microsoftonline.com/8b396516-4a75-4d82-a217-ab9b2c9383a7/oauth2/v2.0/token',
  
  logoutUrl: 'https://login.microsoftonline.com/8b396516-4a75-4d82-a217-ab9b2c9383a7/oauth2/logout',
  

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'openid',
  showDebugInformation: true,
  requireHttps: false,
  strictDiscoveryDocumentValidation: false,
};
