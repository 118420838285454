import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';


export const googleAuthCodeFlowConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://accounts.google.com/o/oauth2/v2/auth',
  loginUrl: 'http://accounts.google.com/o/oauth2/v2/auth',

  // URL of the SPA to redirect the user to after login
  redirectUri: `${environment.url}auth/login`,
  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: '290491948493-5jcsj5bdt38071c5hrmp93jbie86fohe.apps.googleusercontent.com',

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  // dummyClientSecret: 'secret',
  // dummyClientSecret: 'pexp',

  responseType: 'code',

  tokenEndpoint: 'https://oauth2.googleapis.com/token',
  logoutUrl: 'https://oauth2.googleapis.com/revoke',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'openid https://www.googleapis.com/auth/userinfo.email',
  showDebugInformation: true,
  requireHttps: false,
  strictDiscoveryDocumentValidation: false,
};
