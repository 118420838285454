import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SquareButtonComponent } from './square-button/square-button.component';
import { IconComponent } from './icon/icon.component';
import { ImageHolderComponent } from './image-holder/image-holder.component';
import { TitleComponent } from './title/title.component';
import { TableComponent } from './table/table.component';
import { LabelComponent } from './label/label.component';

const COMPONENTS = [
  SquareButtonComponent,
  IconComponent,
  ImageHolderComponent,
  TitleComponent,
  TableComponent,
  LabelComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule],
  exports: [...COMPONENTS],
})
export class LibModule {}
