import { Component, OnInit } from '@angular/core';
import { AppVersionService } from 'src/app/_services/app-version.service';

@Component({
  selector: 'tg-login-left-panel',
  templateUrl: './login-left-panel.component.html',
  styleUrls: ['./login-left-panel.component.scss']
})
export class LoginLeftPanelComponent implements OnInit {

  constructor(
    public appVersionService: AppVersionService,) { }

  ngOnInit(): void {
  }

}
