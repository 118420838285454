<div
  class="
    square-button
    d-flex
    flex-column
    justify-content-center
    align-items-center
    mx-2
  "
  [ngStyle]="style"
>
  <span class="first-letters" *ngIf="!backgroundImageUrl">{{
    firstLetters
  }}</span>
  <ng-content></ng-content>
  <!-- <img src="assets/images/employee.jpg" alt="John Doe" /> -->
</div>
