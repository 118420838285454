import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { StatDataModel } from 'src/app/_models/app.models';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'tg-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent implements OnInit {
  statCardModels: StatDataModel[] = [
    {
      title: 'Unreported Expenses',
      quantity: 1,
    },
    {
      title: 'Awaiting Reimbursements',
      quantity: 2,
    },
    {
      title: 'Awaiting Approval',
      quantity: 3,
    },
    {
      title: 'Submitted Reports',
      quantity: 5,
    },
    {
      title: 'Unsubmitted Reports',
      quantity: 0,
    },
  ];

  items: MenuItem[];

  isLoginRoute: Observable<boolean> | undefined;

  constructor(private router: Router, private ar: ActivatedRoute) {
    this.items = [
      {
        label: 'File',
        items: [
          {
            label: 'New',
            icon: 'pi pi-fw pi-plus',
            items: [{ label: 'Project' }, { label: 'Other' }],
          },
          { label: 'Open' },
          { label: 'Quit' },
        ],
      },
      {
        label: 'Edit',
        icon: 'pi pi-fw pi-pencil',
        items: [
          { label: 'Delete', icon: 'pi pi-fw pi-trash' },
          { label: 'Refresh', icon: 'pi pi-fw pi-refresh' },
        ],
      },
    ];
  }

  ngOnInit(): void {}
}
