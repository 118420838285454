import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

  transform(value: string): string {
    if (value.length <= 15) {
      return value;
    }

    const lastPart = value.substring(value.length-6);
    const firstPart = value.substring(0, 5);

    return `${firstPart}...${lastPart}`;
  }

}
