import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'tg-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit {
  @Input() title: string = '';
  fontSizeSubject = new BehaviorSubject<string>('');

  @Input() set level(value: number) {
    switch (value) {
      case 1: {
        this.fontSizeSubject.next('2rem');
        return;
      }
      case 2: {
        this.fontSizeSubject.next('1.6rem');
        return;
      }
      case 3: {
        this.fontSizeSubject.next('1.2rem');
        return;
      }
      case 4: {
        this.fontSizeSubject.next('1rem');
        return;
      }
      default: {
        this.fontSizeSubject.next('1rem');
        return;
      }
    }
  }

  constructor() {}

  ngOnInit(): void {}
}
