import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[tgCurrencyFormatter]'
})
export class CurrencyFormatterDirective { 

  constructor(private el: ElementRef, private control: NgControl) {}

  ngOnInit(){
    // Listen to value changes on the form control and format the value automatically
    this.control.control?.valueChanges.subscribe(value => {
      if (value != null && value !== '') {
        this.el.nativeElement.value = this.formatToIndianCurrency(value);
      }
      else if(value == null || value == ''){
        this.el.nativeElement.value = '';
      }
    });

    // Format the initial value when the directive initializes
    // const initialValue = this.control.control?.value;
    // if (initialValue) {
    //   this.el.nativeElement.value = this.formatToIndianCurrency(initialValue);
    // }
  }

  @HostListener('blur')
  onBlur() {
    const value = this.parseCurrency(this.el.nativeElement.value);
    this.control.control?.setValue(value, { emitEvent: false });
    value && (this.el.nativeElement.value = this.formatToIndianCurrency(value));
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Allow only digits and a single decimal point
    const sanitizedValue = value.replace(/[^0-9.]/g, '');
    // Prevent multiple decimal points in the input
    const parts = sanitizedValue.split('.');
    if (parts.length > 2) {
      this.el.nativeElement.value = parts[0] + '.' + parts[1]; //+ parts[1]; // Keep only the first decimal point
    } else {
      this.el.nativeElement.value = sanitizedValue;
    }

    // const parsedValue = this.parseCurrency(this.el.nativeElement.value);
    let val = this.el.nativeElement.value;
    this.control.control?.setValue(val, { emitEvent: false }); 
  }

  private formatToIndianCurrency(value: number | string): string | null {
    value = value.toString().replace(/[^0-9.]/g, '');
    if(value === ''){
      return null;
    }
    if (typeof value === 'string') {
      value = parseFloat(value);
    }
    return value.toLocaleString('en-IN', {
      currency: 'INR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  
  private parseCurrency(value: string): number | null  {
    if(value.length > 0){
      console.log(value);
      console.log(value?.replace(/[^0-9.]+/g, ''));
      return parseFloat(value?.replace(/[^0-9.]+/g, ''));
    }
    else{
      return null;
    } 
  }

}
