import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'tg-document-detail-count-widget',
  templateUrl: './document-detail-count-widget.component.html',
  styleUrls: ['./document-detail-count-widget.component.scss']
})
export class DocumentDetailCountWidgetComponent implements OnInit {
  @Input() documentName!: string;
  @Input() draftCount!: number;
  @Input() approvedCount!: number;
  @Input() rejectCount!: number;
  @Input() submitCount!: number;
  @Input() draftAmount!: number;
  @Input() approvedAmount!: number;
  @Input() rejectAmount!: number;
  @Input() submitAmount!: number;
  @Input() holdCount!: number;
  @Input() parkedCount!: number;
  @Input() postedCount!: number;
  @Input() paidCount!: number;
  @Input() holdAmount!: number;
  @Input() parkedAmount!: number;
  @Input() postedAmount!: number;
  @Input() paidAmount!: number;
  @Output() navigateDocument = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }
  navigateToDocument(status: string): void {
    // Navigating with state data
    this.navigateDocument.emit(status)

  }
}
