<div class="widget-header">
    <div class="widget-header_left">
        <div class="widget-title">{{widgetTitle}}</div>
        <div class="widget-subtitle">Last Updated On {{lastUpdatedDate}}</div>
    </div>
    <div class="widget-header_right">
        <span class="p-input-icon-left" *ngIf="widgetTitle === 'Budget Details'">
            <i class="pi pi-search"></i>
            <input placeholder="Search...." [(ngModel)]="quickSearchValue" (blur)="onBlurEmitValue()" pInputText
                class="border-radius-right" />
        </span>
        <button class="btn btn-sm btn-outline-secondary export" type="button"><i
                class="bi bi-file-earmark-excel me-2"></i> Export</button>
    </div>
</div>