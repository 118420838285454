import { Component, ContentChild, Input, OnInit } from '@angular/core';
import { UtilService } from 'src/app/_services/util.service';

@Component({
  selector: 'tg-square-button',
  templateUrl: './square-button.component.html',
  styleUrls: ['./square-button.component.scss'],
})
export class SquareButtonComponent implements OnInit {
  @Input() backgroundImageUrl: string = '';
  @Input() backgroundColorHex: string = '#154170';
  @Input() foregroundColorHex: string = '#DDD';
  @Input() displayText: string = '';

  constructor(private utilService: UtilService) {}

  ngOnInit(): void {}

  get style(): object {
    return {
      'background-image': `url("${this.backgroundImageUrl}")`,
      'background-position': 'center',
      'background-repeat': 'no-repeat',
      'background-size': 'cover',
      'background-color': this.backgroundColorHex,
      color: this.foregroundColorHex,
    };
  }

  get firstLetters(): string {
    return this.utilService.getInitialLetters(this.displayText);
  }
}
