<div class="flex-between-v-center px-4 py-3 cluster-bar" *ngIf="showTitle">
    <span class="cluster-title">
        Supporting Documents
    </span>
    <div class="flex-v-center">
        <i class="bi bi-x-lg pointer" (click)="onClose()"></i>
    </div>
</div>
<div>
    <ng-container>
        <p-table #table [globalFilterFields]="['name','createdBy']" [paginator]="false" [responsive]="true"
            [value]="localDocumentList" [selectionPageOnly]="true" responsiveLayout class="documents-table">
            <ng-template pTemplate="caption">
                <div class="flex-between-v-center">
                    <!-- policy-heading -->
                    <div class="flex-v-center">
                        <span class="queue-heading">Max. size to upload : 12MB | Upload document in png, jpg, pdf
                            format</span>
                    </div>

                    <div class="flex-gap-2">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input (input)="table.filterGlobal($event.target.value, 'contains')" pInputText
                                placeholder="Search" type="text" />
                        </span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Document Name</th>
                    <th>Created By</th>
                    <th>Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr>
                    <td>{{rowData.name}}</td>

                    <td><span>{{rowData.createdBy}}</span><br />
                        <span>{{rowData.createdAt | date: 'dd-MM-yyyy hh:mm a' }}</span>
                    </td>
                    <td>
                        <div class=" action">
                            <i (click)="downloadDocument(rowData.url,rowData.name)"
                                class="bi bi-cloud-arrow-down pointer" pTooltip="Download"></i>&nbsp;
                            <i (click)="deleteDocument(rowData.id)" *ngIf="showDeleteAction" class="bi bi-trash pointer"
                                pTooltip="Delete"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
</div>